.folder-modal-wrapper {
  position: absolute;
  top: 2.5%;
  height: 95%;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 4%;
  background-color: white;
  z-index: 100;
}

/* FOLDER MODAL HEADER */

.folder-modal-header {
  width: 100%;
  display: flex;
  gap: 2%;
}

.folder-modal-header-btn {
  flex: 1;
  aspect-ratio: 1/1;
  position: relative;
}

.folder-modal-header-img {
  height: 60%;
  width: 60%;
}
.visible-img {
  height: 60% !important;
}

/* FOLDER MODAL MAIL */

.folder-modal-mail {
  width: 100%;
  height: 5%;
  display: flex;
  background-color: #acababcc;
  color: gray;
}

.folder-modal-mail-counter {
  height: 100%;
  flex: 1;
  padding: 0.5% 1%;
}

.folder-modal-mail-new-documents {
  height: 100%;
  flex: 6;
  padding: 0.5% 1%;
}

.folder-modal-mail-size {
  height: 100%;
  flex: 1;
  padding: 0.5% 1%;
}

/* FOLDER MODAL FOLDERS */

.folder-modal-folders-wrapper {
  flex: 1;
  width: 100%;
  overflow-y: auto;
}

.folder-modal-folders {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.folder-modal-file {
  height: 7vh;
  width: 100%;
  background-color: #acababcc;
  cursor: pointer;
}

/* REGULAR FILE */

.regular-file {
  height: 100%;
  display: flex;
  width: 100%;
}

.regular-file-number {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5% 1%;
}

.regular-file-name {
  flex: 6;
  height: 100%;
  text-align: left;
  padding: 0.5% 1%;
}

.regular-file-btn {
  height: 100%;
  aspect-ratio: 1/1;
}
.wrapper-regular {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5% 1%;
  flex: 6 1;
}

.document-view-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.wrapper-header {
  display: flex;
  width: 100%;
  height: 10%;
}

.wrapper-header-folder {
  display: flex;
  width: 100%;
  height: 10%;
}
.document-view {
  height: 100%;
  width: 100%;
}
.document-title {
  display: flex;
  align-items: center;
  padding-left: 2%;
  font-size: 20px;
  font-weight: 700;
  width: 85%;
  height: 100%;
}

.full-screen {
  display: flex;
  height: 100%;
  aspect-ratio: 1/1;
  color: gray;
}

.wrapper-add-documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
}
.wrapper-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eceaea;
  align-items: center;
  height: 10vh;
}
.wrapper-description > input {
  border: none;
  background-color: #eceaea;
  width: 100%;
  height: 50%;
}
.wrapper-description > span {
  display: flex;
  color: red;
  height: 50%;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}
.wrapper-title {
  display: flex;
  flex-direction: column;
  height: 10vh;
  width: 100%;
}
.wrapper-title-button {
  width: 100%;
  display: flex;
  height: 70%;
  background-color: #eceaea;
}
.wrapper-title-button > input {
  border: none;
  background-color: transparent;
  flex: 6 1;
}
.wrapper-title > span {
  display: flex;
  color: red;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
.wrapper-button-folder {
  height: 100%;
  aspect-ratio: 1/1;
}
.wrapper-button > span {
  width: 100%;
  height: 30%;
}
#file-upload {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
}

/* DETAILED FILE */

.folder-modal-file:has(.detailed-file) {
  height: auto;
}

.detailed-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5%;
}

.detailed-file-header {
  height: 4vh;
  width: 100%;
  display: flex;
}

.detailed-file-header > span {
  font-size: 10px;
}

.detailed-file-number {
  flex: 1;
  height: 100%;
}

.detailed-file-name {
  flex: 5;
  height: 100%;
  text-align: start;
}

.detailed-file-time {
  flex: 2;
  height: 100%;
  text-align: end;
}

.detailed-file-middle {
  height: 9vh;
  width: 100%;
  display: flex;
}

.detailed-file-title {
  flex: 9;
  height: 100%;
  text-align: start;
  font-size: 14px;
  padding: 1%;
  font-weight: 600;
}

.detailed-file-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.detailed-file-data > span {
  font-size: 10px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailed-file-footer {
  width: 100%;
  display: flex;
}

.detailed-file-description {
  flex: 8;
  font-size: 12px;
  line-height: 5px;
  padding: 0.5%;
}

.detailed-file-btn {
  flex: 1;
  aspect-ratio: 1/1;
}

.detailed-file-btn-img {
  height: 60%;
  width: 60%;
}

.document-view-wrapper > * #viewerContainer {
  top: 16% !important;
  height: 60vh !important;
}

.document-view-wrapper > * .mobile__pdf__container__header {
  width: 100%;
  position: absolute;
  top: 10% !important;
}

.document-view-wrapper > * .mobile__pdf__container footer {
  bottom: 30% !important;
  position: absolute !important;
}

.document-view-wrapper > * .pdfViewer .canvasWrapper {
  width: unset !important;
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-view-wrapper > * .pdfViewer .page {
  border-left: none;
  border-right: none;
}
@media (orientation: landscape) {
  .folder-modal-wrapper {
    right: 1.5%;
    left: unset;
    top: 2%;
    width: 47%;
    height: 94vh;
  }
}
