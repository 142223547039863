.post-button {
    position: fixed;
    bottom: 2rem;
    left: 42%;
    height: 6rem;
    width: 6rem;
    z-index: 2;
}

.post-button > button {
    height: 100%;
    width: 100%;
    font-size: 5rem;
}

.post-button > button > img {
    height: 3rem;
}