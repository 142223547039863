.post-form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
   
}

.post-form-wrapper textarea {
    border-radius: 10px;
    resize: none;
    width: 95%;
    margin-top: 20px;
    padding: 2px 5px;
    transition: height 0.2s ease-in-out; /* Smooth height transition */
    overflow: hidden; /* Hide the scrollbar */
    font-family: Arial;
}

.post-form-wrapper textarea:nth-child(2) {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
}
#title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
    border-radius: 10px;
    resize: none;
    width: 95vw;
    margin-top: 20px;
    padding: 2px 5px;
    transition: height 0.2s ease-in-out; /* Smooth height transition */
    overflow: hidden; /* Hide the scrollbar */
    font-family: Arial;
}


.post-form-wrapper textarea:nth-child(3) {
    font-size: 18px;   
}

.form-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 15px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}


.form-top-buttons img {
    width: 45%;
    height: auto;
}

.category-squares {
    display: flex;
    justify-content: space-around;
    width: 90vw;
    margin-top: 15px;
}

.category-square {
    width: 40px;
    height: 40px;
    border: 2px solid;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.category-square:nth-child(1) {
    border: 2px solid #00b055ff;
}
.category-square:nth-child(2) {
    border: 2px solid #418cfcff;
}
.category-square:nth-child(3) {
    border: 2px solid #ff3519ff;
}
.category-square:nth-child(4) {
    border: 2px solid #ffd42aed;
}

.category-1 {
    border-color: #00b055ff;
}

.category-2 {
    border-color: #418cfcff;
}

.category-3 {
    border-color: #ff3519ff;
}

.category-4 {
    border-color: #ffd42aed;
}

.category-square.selected.category-1 {
    background-color: #00b055ff;
}

.category-square.selected.category-2 {
    background-color: #418cfcff;
}

.category-square.selected.category-3 {
    background-color: #ff3519ff;
}

.category-square.selected.category-4 {
    background-color: #ffd42aed;
}

.image-preview {
    position: relative;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-preview img {
    max-width: 90vh;
    max-height: 200px;
    border-radius: 10px;
  }
  
  .cancel-image {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    padding: 2px 6px;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    width: 1.55rem;
  }
  
  .cancel-image:hover {
    background-color: rgba(255, 0, 0, 0.9);
  }
  
