/*
  250px - 400px da bude funkcionalno
  400px - 700px da bude funkcionalno
  700px - landscape
*/

.filter-archive {
  position: absolute;
  top: calc(3% + 12.5vh);
  width: 90%;
  height: 84vh;
  padding: 2%;
  display: flex;
  flex-direction: column;
  background-color: gray;
  border-radius: 5px;
  z-index: 10;
  align-self: center;
  justify-self: center;
}

.wrap-dates {
  display: flex;
  flex-direction: column;
  gap: 2%;
  height: 40%;
  padding: 5% 0;
}

.date {
  display: flex;
  align-items: flex-start;
  align-items: center;
  width: 100%;
  height: 50%;
}
.date > span {
  padding-left: 10%;
  font-family: "DS-Digital", sans-serif;
  font-size: 65px;
}

.date-calendar {
  display: flex;
  width: 35%;
  height: 100%;
}

.start-date-calendar-img {
  width: 100%;
}

.date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
}

.date > span {
  padding-left: 10%;
  font-family: "DS-Digital", sans-serif;
  font-size: 55px;
}

.end-date-calendar-img {
  width: 100%;
  opacity: 1;
}

.end-date-calendar-img.blurred {
  opacity: 0.2;
}

.end-date-calendar > span {
  align-self: center;
  justify-self: center;
}

.bottom-container {
  display: flex;
  justify-content: end;
  height: 60%;
  gap: 1%;
}

.vertical-button-row-1 {
  display: flex;
  flex-direction: column;
  gap: 3%;
  height: 100%;
  width: 20%;
}

.first-group {
  display: flex;
  width: 100%;
  height: 23.5%;
}

.button-message {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  position: relative;
}

.file-message {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  position: relative;
}

.link-message {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  position: relative;
}

.image-message {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  position: relative;
}

.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
  height: 50%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  position: absolute;
  right: 2%;
  top: -20%;
}

.dot-search {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
  height: 26.5%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: white;
  background-color: transparent;
  position: absolute;
  right: 47%;
  top: 30%;
}
.vertical-button-row-2 {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 2%;
  height: 100%;
  width: 25%;
}

.chart {
  display: flex;
  width: 100%;
  height: 25%;
  align-self: center;
  justify-self: center;
}

.profile {
  display: flex;
  width: 100%;
  height: 25%;
  align-self: center;
  justify-self: center;
}

.vertical-button-row-3 {
  display: inline-flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 2%;
  height: 100%;
  width: 55%;
}

.colors {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.row-flex {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.row-flex > span {
  padding-right: 10%;
  font-size: 25px;
}

.info-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 3vh;
  height: 50%;
}

.gray {
  background-color: rgb(94, 88, 88);
}

.red {
  background-color: rgb(255, 0, 0);
}

.green {
  background-color: rgb(17, 228, 27);
}

.search-container {
  display: flex;
  flex-direction: column;
  height: 65%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.search {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.button-div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-filter-category {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 300px) {
  .date > span {
    width: 70%;
    font-size: 35px;
  }

  .date > span {
    width: 70%;
    font-size: 30px;
  }

  .date-calendar {
    display: flex;
    object-fit: cover;
    width: 45%;
    height: 80%;
  }

  .row-flex > span {
    padding-right: 10%;
    font-size: 12px;
  }

  .info-color {
    width: 2vh;
    height: 30%;
  }

  .search-container {
    font-weight: 700;
    font-size: 15px;
  }

  .vertical-button-row-1 {
    width: 30%;
  }

  .first-group {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .button-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .file-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .link-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .image-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .dot {
    height: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: white;
    border-style: solid;
    position: absolute;
    right: 2%;
    top: -20%;
  }

  .vertical-button-row-2 {
    position: relative;
    width: 30%;
  }

  .chart {
    display: flex;
    width: 100%;
    height: 26%;
    align-self: center;
    justify-self: center;
  }

  .profile {
    display: flex;
    width: 100%;
    height: 26%;
    align-self: center;
    justify-self: center;
  }

  .vertical-button-row-3 {
    width: 40%;
  }

  .search > button > img {
    width: 80%;
    height: 80%;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
  .date-calendar {
    display: flex;
    width: 45%;
    height: 80%;
  }
  .date > span {
    font-size: 45px;
    padding-left: 10%;
  }

  .first-group {
    display: flex;
    width: 100%;
    height: 23.5%;
  }

  .button-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .file-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .link-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .image-message {
    display: flex;
    width: 90%;
    height: 90%;
    position: relative;
  }

  .dot {
    height: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: white;
    border-style: solid;
    position: absolute;
    right: 2%;
    top: -20%;
  }

  .chart {
    display: flex;
    width: 100%;
    height: 25%;
    align-self: center;
    justify-self: center;
  }

  .profile {
    display: flex;
    width: 100%;
    height: 25%;
    align-self: center;
    justify-self: center;
  }

  .date > span {
    font-size: 45px;
  }
  .row-flex > span {
    padding-right: 10%;
    font-size: 12px;
  }
  .info-color {
    width: 3vh;
    height: 50%;
  }
  .search-container {
    font-weight: 700;
    font-size: 15px;
  }
  .vertical-button-row-1 {
    width: 30%;
    gap: 5%;
  }
  .vertical-button-row-2 {
    width: 30%;
  }
  .vertical-button-row-3 {
    width: 40%;
  }
}

@media screen and (min-width: 200px) and (max-width: 400px) {
  .dot-search {
    right: 47%;
    top: 22%;
    height: 31%;
  }
}

@media screen and (min-width: 500px) and (max-width: 700px) {
  .dot-search {
    right: 48%;
    top: 22%;
    height: 32%;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
  .date > span {
    font-size: 35px;
  }

  .dot-search {
    right: 48%;
    top: 24%;
    height: 34%;
  }
  .vertical-button-row-1 {
    gap: 6%;
  }

  .vertical-button-row-2 {
    gap: 3%;
  }

  .info {
    font-size: 20px;
    flex: 1;
    justify-content: end;
  }
  .search-container {
    font-weight: 700;
    font-size: 25px;
  }
  .search {
    padding-top: 5%;
  }
}

@media screen and (min-width: 700px) {
  .date > span {
    font-size: 45px;
  }
  .date > span {
    font-size: 45px;
  }
  .search-container {
    font-weight: 700;
    font-size: 25px;
  }
  .dot-search {
    height: 28%;
    right: 48%;
    top: 25%;
  }
  .info {
    font-size: 20px;
    flex: 1;
    justify-content: end;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
