.editor {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 5%;

  position: relative;
  height: auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* EDITOR HEADER */

.editor-header {
  display: flex;
  width: 100%;
  gap: 2vh;
  height: 10vh;
}

.editor-line-scroll {
  display: none;
}

.z-index-landscape {
  z-index: 15;
}

.editor-header-btn {
  height: 100%;
  width: 18%;
}

.editor-header-middle {
  width: 60%;
  height: 100%;
}

.editor-header-middle-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.editor-header-middle-archive {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.editor-header-middle-btn {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  letter-spacing: 2px;
  color: #4e4d4d;
}

.editor-header-archive-btn.cancel {
  height: 100%;
  width: 30%;
}

.editor-header-archive-btn.filter {
  height: 100%;
  width: 65%;
}

.archive-icon {
  height: 80%;
  width: 70%;
  object-fit: contain;
}

.editor-header-middle > span {
  height: 30%;
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  color: #111;
  white-space: nowrap;
}

.editor-header-middle-span {
  font-size: 30px;
  letter-spacing: 1px;
  font-family: "DS-Digital", sans-serif;
}

/* EDITOR INFORMATION */

.editor-information {
  width: 100%;
  height: 12vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #111;
}

.editor-information-header {
  height: 30%;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #111;
  font-size: 18px;
  background-color: #eceaea;
}

.editor-information-header > .number,
.editor-information-header > .time,
.editor-information-header > .name {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2%;
}

.editor-information-header > .number {
  border-right: 1px solid #111;
}

.editor-information-header > .time {
  border-left: 1px solid #111;
}

.editor-information-header > .name {
  width: 70%;
}

.editor-information-textarea {
  height: 70%;
  width: 100%;
  display: flex;
}

.editor-information-textarea > textarea {
  resize: none;
  width: 85%;
  height: 100%;
  padding: 1%;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}

.editor-information-textarea-btn {
  width: 15%;
  height: 100%;
}

.editor-data-wrapper {
  height: 81vh;
  overflow-y: scroll;
  position: relative;
}

.editor-data-wrapper.archive-active {
  height: 80vh;
}

.editor-data-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

/* EDITOR BUTTONS TOP */

.editor-buttons-top {
  width: 100%;
  height: 10vh;
  display: flex;
  gap: 2vh;
}

.editor-buttons-top-btn {
  height: 100%;
  width: 20%;

  outline: none;
  border: none;
  opacity: 0.2;
  pointer-events: none;
  position: relative;
}

.editor-buttons-top-btn.active {
  opacity: 1;
  pointer-events: auto;
}

.editor-buttons-top-btn:last-child {
  width: 36%;
}

.editor-button-top-circle {
  position: absolute;
  top: -1.5vh;
  right: 0;
  height: 40%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #fff;
  border: 2px solid #111;
  font-weight: 700;
  border-radius: 50%;
}

/* EDITOR LINE AND DATE */

.editor-line {
  background-color: #111;
}

.editor-date {
  align-self: center;
  font-size: 30px;
  letter-spacing: 1px;
  font-family: "DS-Digital", sans-serif;
}

/* EDITOR BUTTONS */

.editor-buttons {
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  gap: 2vh;
}

.editor-buttons-btn {
  height: 100%;
  width: 24%;
  pointer-events: auto;
}

.editor-buttons-btn.not-active {
  opacity: 0.2;
  pointer-events: none;
}

.visible-img {
  height: 60% !important;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 0vw;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (orientation: landscape) {
  .editor {
    height: 100vh;
    padding: 1.5%;
    gap: 4vh;
  }

  .editor-top {
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .editor-top-left,
  .editor-top-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 48%;
    gap: 1vh;
  }

  .editor-information {
    height: 15vh;
  }

  .editor-buttons-top {
    height: 12vh;
  }

  .editor-header {
    height: 15vh;
  }

  .editor-header-btn {
    width: 27%;
  }

  .editor-buttons {
    height: 13vh;
  }

  .editor-bottom-wrapper {
    height: 65%;
    width: 100%;
    overflow-y: auto;
  }

  .editor-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
  }
}

@media (orientation: portrait) {
  .editor-header {
    gap: 0;
  }
  .editor-header-btn {
    width: 20%;
  }

  .editor-buttons-top {
    gap: 0;
  }
  .editor-buttons-btn {
    width: 25%;
  }
  .editor-buttons-top-btn:last-child {
    width: 40%;
  }
  .editor-buttons {
    gap: 0;
  }
  .editor-header-archive-btn.filter {
    width: 70%;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
