.user-bloc {
    width: 100%;
    margin: 0 auto;
    font-size: 1.3rem;
    border: 1px solid black;
}

.user-bloc--user-info {
    padding: 0.8rem 0.3rem;
    text-align: center;
}

.user-bloc--user-info--rating-container {
    
}

.user-bloc--user-info--rating-container > img {
   
}

.user-bloc--user-info--rating-container--value {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
}

.user-bloc--user--counts {
    padding: 0 0.5rem 0.7rem 0.5rem;
}

.user-bloc--user--counts--count > img {
    height: 2rem;
}

.user-followed .user-bloc--user-info div p {
    color: var(--fav-follow-color);
}

.user-favorited {
    background-color: var(--fav-follow-color);
}

.user-blocked .user-bloc--user-info div p  {
    color: var(--blocked-color);
}

a.no-style {
    width: 100%;
}

.reset-a, .reset-a:hover, .reset-a:visited, .reset-a:focus, .reset-a:active{
    text-decoration: none;
    color: inherit;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}