html, body {
    overflow: visible;
    overscroll-behavior: auto;
    overflow-y: visible !important;
}


.sticky {
    transition: position 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow to the fixed bar */
}

.control-buttons img {
    width: 50%;
}

.control-buttons {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;
    background-color: var(--background-color); /* Ensure the background is not transparent */
    padding: 10px 0;
    z-index: 2; /* Ensure it's on top of other content */
    transition: all 0.3s ease;

}

.container-sticky {
    position: sticky;
    top: 0;
}