:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

.check-cor-trusted-contacts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.instruction {
  border: 1px solid gainsboro;
  margin: 20px;
  text-align: center;
  width: 80vw;
}

.btns {
  display: flex;
  width: 100%;
  height: 70%;
}

.btn-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10%;
  height: 12%;
}

.btns button {
  font-size: 2em;
}

.button-trust-contact {
  width: 50%;
  height: 100%;
}

.activeButton {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  background: var(--button-active);
}

.trust-contact-input {
  border-top: var(--border-dark) 2px solid;
  border-bottom: var(--border-light) 2px solid;
  border-left: var(--border-light) 2px solid;
  border-right: var(--border-dark) 2px solid;
  height: 10mm;
  margin: 10px 0;
  font-size: 1.3em;
  width: 80vw;
}

.trusted-contact {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.trusted-contact img {
  width: 150px;
}

.send-button-truste-contact {
  width: 70vw;
  background-color: red;

  bottom: 50px;
}

.send-button-truste-contact button {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button-truste-contact img {
  width: 60px;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
