
.loading-screen {
    height: 60vh;
}

.clock {
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: solid 5px black;

}

.hours {
    position: absolute;
    width: 140px;
    height: 10px;
    top: 66px;
    animation: spin-round 5s linear 0s infinite;
}

.hours:before {
    content: "";
    height: 10px;
    width: 50px;
    position: absolute;
    right: 25px;
    background: black;
    border-radius: 15px;
}

.minutes {
    position: absolute;
    width: 140px;
    height: 10px;
    top: 66px;
    animation: spin-round 1.2s linear 0s infinite;
}

.minutes:before {
    content: "";
    height: 10px;
    width: 66px;
    position: absolute;
    right: 9px;
    background: black;
    border-radius: 15px;
}

@keyframes spin-round {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}