.message-list {
  position: relative;
  padding: 2%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1%;
  overflow-y: auto;
}

.wrapper-message-element {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.message-element {
  width: 100%;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  background-color: white;
  margin: 5px 0;
}
.wrapper-message-user {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.wrapper-data-info {
  display: flex;
  width: 100%;
  align-items: center;
}
.count-message {
  display: flex;
  align-items: center;
  border-radius: 10%;
  width: 60px;
  height: 70%;
  margin: 2%;
  padding-left: 2%;
  color: white;
}
.name-message {
  width: 60%;
  height: 100%;
  padding-top: 1%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.time-message {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: center;

  margin: 0 2%;
  justify-content: flex-end;
}

.text-message {
  width: 100%;
  padding: 0 5%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 2%;
}
.time-span {
  display: flex;
  align-self: center;
  font-size: 20px;
  color: black;
}

.search-contact {
  display: flex;
  width: 100%;
  height: 50px;
}

.search-contact-landscape {
  display: flex;
  width: 100%;
  height: 50px;
}

.search-contact-landscape > input {
  width: 100%;
  height: 100%;
  padding: 0 2%;
}

.search-contact > input {
  width: 100%;
  height: 100%;
  padding: 0 2%;
}

.button-search {
  height: 100%;
  aspect-ratio: 1/1;
}
.new-user-wrapper {
  position: absolute;
  top: 8.5%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 5% 0;
  background-color: white;
  align-items: center;
  width: 96%;
  height: 50%;
}
.new-user-wrapper > span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 80%;
  width: 80%;
  padding: 5%;
  border: 2px solid black;
  font-size: 20px;
}

.send-new-user {
  width: 80%;
  height: 20%;
}

.visible-img {
  height: 60% !important;
}

/* Test comment*/
