.terms-of-use-wrapper {
  width: 93%;
  height: 100%;
  padding: 2%;
  padding-bottom: 8%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  gap: 2%;
}
.close-terms {
  width: 40px;
  object-fit: contain;
  display: flex;
  align-self: flex-end;
}

.title-terms {
  width: 50%;
  font-size: 17px;
  font-weight: 700 !important;
  text-align: center;
}

.send-terms {
  height: 10vh;
  aspect-ratio: 4/1;
  max-width: 80%;
  min-height: 60px;
}

.send-terms-landscape {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  aspect-ratio: 5/1;
  width: 50%;
}

.content-terms {
  height: 70%;
  width: 100%;
  padding: 0 5%;
}

.send-terms-span {
  height: 100%;
  width: 100%;
  font-size: 15px;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 730px) {
  .terms-of-use-wrapper-landscape {
    display: none;
  }
}

@media only screen and (min-width: 730px) {
  .terms-of-use-wrapper {
    display: none;
  }

  .terms-of-use-wrapper-landscape {
    width: 100vw;
    height: 100vh;
  }

  .terms-of-use-wrapper-landscape-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 90%;
  }

  .send-terms {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .terms-of-use-wrapper-landscape-top button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .terms-of-use-wrapper-landscape-top .title-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }

  .terms-of-use-wrapper-landscape {
    .close-terms {
      position: absolute;
      top: 3%;
      right: 2%;
      height: 5%;
      aspect-ratio: 1/1;
    }
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}
.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
