.archive {
  position: absolute;
  top: 2.5%;
  width: 48.5%;
  height: 95%;
  display: flex;
  flex-direction: column;
  background-color: #ebe7e7;
  z-index: 50;
}

.archive-header {
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.archive-arrow {
  height: 90%;
  width: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.archive-arrow:first-child {
  justify-content: flex-start;
}

.archive-arrow-img {
  height: 80%;
  width: 80%;
  object-fit: contain;
}

.archive-text-month {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-family: "DS-Digital", sans-serif;
  color: #999999ff;
}

.archive-text-month > span {
  margin: 0 5%;
}

.archive-list {
  background-color: white;
  width: 100%;
  height: 90%;
  border-radius: 5px;
  overflow-y: auto;
}

.archive-list-wrapper {
  width: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3vh;
}

.archive-list-wrapper-item {
  width: 100%;
  height: 9vh;
  display: flex;
  gap: 1%;
}

.archive-list-item {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.archive-list-right-item {
  flex: 1;
  height: 100%;
  width: 47%;
  display: flex;
  gap: 2%;
}

.archive-list-right-item-box {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.archive-list-right-item-box > .circle {
  position: absolute;
  top: -10%;
  right: 5%;
  height: 45%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #111;
  border-radius: 50%;
  font-size: 14px;
}

.archive-list-right-item-box > img {
  height: 80%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.archive-list-item-line {
  position: absolute;
  top: 100%;
  left: 25%;
  height: 3vh;
  width: 0;
  border: 1px solid red;
}

.archive-list-item-comments {
  width: 30%;
  height: 100%;
  pointer-events: auto;
}

.archive-list-item-comments.not-active,
.archive-list-item-date.not-active {
  opacity: 0.5;
  pointer-events: none;
}

.comment-number {
  font-size: 25px !important;
  margin-right: auto;
  margin-left: 10%;
}

.comment-img {
  height: 50% !important;
  width: 45% !important;
  object-fit: contain;
}

.archive-list-item-date {
  width: 67%;
  height: 100%;
  pointer-events: auto;
}

.archive-date {
  display: flex;
  font-family: "DS-Digital", sans-serif;
  font-size: 35px !important;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.wrapper-button-archive {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.odd {
  background-color: #999999ff;
}

.even {
  background-color: #d6d6d6ff;
}

@media (orientation: portrait) {
  .archive {
    width: 90%;
    height: 86vh;
    top: 15vh;
  }
}

@media (orientation: landscape) {
  .archive-list {
    background-color: white;

    height: 83%;
    border-radius: 5px;
    overflow-y: auto;
    position: absolute;
    top: 17%;
    left: 0;

    width: 97vw;
  }
}
