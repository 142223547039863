.home-page {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  aspect-ratio: 1/2;
}

.home-page-landscape {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  gap: 1.3%;
}
.wrapper-item-button {
  display: flex;
  width: 49%;
  aspect-ratio: 1/1;
  margin: 1% 0;
}
.wrapper-item-button-landscape {
  width: 24%;
  aspect-ratio: 1 / 1;
  margin-bottom: 0.65%;
}

.wrapper-item-button:nth-child(odd) {
  margin-right: 1%;
}

.wrapper-item-button:nth-child(even) {
  margin-left: 1%;
}

.home-page-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-image > img {
  height: 60%;
  object-fit: contain;
}

.home-email {
  font-size: 20px;
}
