.user-feed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-feed > div {
    margin: 10px 0;
    width: 95%;
}

.posts-list {
    padding-bottom: 1rem;
}