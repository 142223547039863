.wrapper-languages-container {
  position: absolute;
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 95%;
  width: 50vh;
  padding-top: 15%;
}

.language-selection-img {
  height: 80% !important;
}

#language-selection-send-btn {
  border: none;
}

#language-selection-add-button {
  border: none;
}

.flags-up {
  position: relative;
  display: flex; /*position:relative??????*/
  height: 25%;
  width: 100%;
  /* justify-content: center; */
}

.flags-center {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50%;
}
.flags-center-side-left {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 20%;
}
.flags-center-side-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 20%;
}
.flags-down {
  position: relative;
  display: flex;
  width: 100%;
  height: 25%;
  justify-content: center;
}
.flags-down-button {
  position: absolute;
  display: flex;
  height: 30%;
  aspect-ratio: 1/1;
}

.flags-down-button > button {
  border-radius: 50%;
}

.one-flag {
  display: flex;
  border-radius: 50%;
  /* height: 50px; */
  align-self: center;
  justify-self: center;
  aspect-ratio: 1 / 1;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.one-flag > img,
.one-flag-up > img,
.one-flag-down > img {
  text-align: center;
  height: 50px !important;
}

.one-flag-up {
  position: absolute;
  border-radius: 50%;
  /* height: 60px; */
  aspect-ratio: 1 / 1;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.one-flag-down {
  position: absolute;
  display: flex;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper-select-flag {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10%;
  gap: 10%;
  width: 60%;
  height: 100%;
}

.wrap-language-send {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5%;
  height: 40%;
  width: 100%;
}
.select-country-button {
  height: 50%;
  width: 100%;
}

.name-country {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.image-flag {
  position: absolute;
  top: -17%;
  align-self: center;
  display: flex;
  height: 45%;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

@media only screen and (min-width: 450px) and (max-width: 600px) and (orientation: landscape) {
  .flags-down-button {
    top: -250%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .flags-center {
    height: 70%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .flags-center {
    height: 80%;
  }
  .flags-down-button {
    top: -380%;
  }
  .wrap-language-send {
    width: 70%;
  }
}

@media only screen and (min-width: 1000px) {
  .flags-center {
    height: 90%;
  }
  .flags-down-button {
    top: -80vh;
    height: 50%;
  }
  .wrap-language-send {
    width: 40vh;
  }
}

@media (orientation: landscape) {
  .wrapper-languages-container {
    transform: rotate(90deg);
    padding-top: 0px;
  }
  .wrapper-languages-container {
    height: 95vw;
    width: 50vw;
  }

  .one-flag {
    transform: rotate(-90deg);
  }
  .one-flag-up {
    transform: rotate(-90deg);
  }
  .one-flag-down {
    transform: rotate(-90deg);
  }
  .wrapper-select-flag {
    transform: rotate(180deg);
  }
  .image-flag {
    transform: rotate(90deg);
  }
  .wrap-language-send {
    transform: rotate(90deg);
  }
}
