.left-side-message-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  background-color: #f9f9f9;
  padding-bottom: 3%;
}

.message-header {
  display: flex;
  height: 8%;
  width: 100%;
}

.message-header.blocked {
  pointer-events: none;
}

.visible-img {
  height: 60% !important;
}

.message-input {
  width: 60%;
  flex: 1;
  padding: 0 2%;
  outline: none;
  pointer-events: auto;
  font-size: 20px;
  border: 1px solid #999999;
}

.btn-up,
.btn-down,
.btn-refresh,
.btn-send {
  height: 100%;
  aspect-ratio: 1 / 1;
}

.message-chat {
  height: 84%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 2%;
}

.msg-reply {
  width: 100%;
  padding: 5%;
  display: flex;
  background-color: #999999;
  font-size: 15px;
}

.msg-reply-information {
  display: flex;
  width: 90%;
  flex-direction: column;
  padding-left: 5%;
}
.msg-reply-information > span:first-child {
  font-weight: 700;
}

.msg-select {
  border: none;
  outline: none;
  cursor: pointer;
  align-self: flex-end;
  background-color: #111;
  color: white;
  height: 50%;
  aspect-ratio: 1/1;
  border-radius: 5px;
}

.message {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 3% 5%;
  align-items: flex-start;
  color: #111;
  border-radius: 10px;
  font-size: 12px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.message.current {
  background-color: #fff;
  align-self: flex-end;
  border-top-right-radius: 0;
  margin-right: 5%;
}

.message.other {
  background-color: #007bff;
  align-self: flex-start;
  border-top-left-radius: 0;
  margin-left: 5%;
}

.msg-data {
  font-size: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.message.current.selected,
.message.other.selected {
  background-color: #28a745;
}
.message.current.checked,
.message.other.checked {
  background-color: yellow;
}

.msg-text {
  margin: 2% 0;
  font-size: 18px;
  font-weight: 500;
}

.message-footer {
  height: 8%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: white;
}

.message-reply {
  position: absolute;
  bottom: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.message-reply-information {
  flex: 1;
  font-size: 15px;
  padding-left: 1%;
  background-color: rgb(243, 204, 204);
  color: #000;
}

.message-sender {
  font-weight: 700;
}

.wrapper-message-date-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
}

.msg-footer-select-left {
  display: flex;
  width: 50%;
}
.msg-footer-select-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}
.msg-select-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.sender-icon-name {
  width: 100%;
  display: flex;
  gap: 3%;
  display: flex;
  align-items: center;
}

.sender-icon-name > img {
  width: 7%;
  object-fit: contain;
}

.msg-footer-select-left > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  color: black;
}
.reply-input-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
