.login-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5%;
  align-items: center;
  height: 90vh;
  width: 100%;
  padding: 0 8%;

  overflow-y: scroll;
  position: fixed;
  bottom: 5%;
}

.wrapper-input-fild {
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 5px;
}

.wrapper-input-fild > img {
  align-self: flex-start;
  height: calc(70vw * 60 / 600);

  object-fit: contain;
}

.loggin-button {
  height: calc(70vw / 6);
  width: 100%;
  display: flex;
  align-self: center;
}

.loggin-button-landscape {
  height: calc(38vw / 5.556);
  width: 100%;
  display: flex;
  align-self: center;
}

.input-key {
  display: flex;
  width: 100%;
  gap: 1.4%;
}

.input-key-landscape {
  display: flex;
  width: 100%;
  gap: 1.4%;
}

.input-key-landscape > input {
  width: calc(100% / 6);
  aspect-ratio: 1 / 1;
  text-align: center;
  border: 1px solid gray;
  font-weight: 700;
}

.input-key > input {
  width: calc(70vw / 6);
  aspect-ratio: 1/1;
  text-align: center;
  border: 1px solid gray;
  font-weight: 700;
}

.input-mail {
  width: 100%;
  height: calc(70vw / 6);
  padding: 0 2%;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:focus {
  outline: none;
}

.register-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  height: 63%;
  width: 90%;
}

.register-wrapper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 0% 5%;
}
.register-wrapper-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  padding: 0% 5%;
}
.register-wrapper-right > img {
  height: 5%;
  object-fit: contain;
}
.register-wrapper-right > input {
  height: 10%;
}

.register-button {
  width: 100%;
  height: 15%;
}

.register-button-img {
  display: flex;
  justify-content: center;
  gap: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
}
.login-form-landscape > img {
  height: calc(38vw * 60 / 600);
  object-fit: contain;
  display: flex;
  align-self: flex-start;
}
.register-button-img > img:first-child {
  height: 40%;
  aspect-ratio: 1/1;
}

.register-text {
  display: flex;
  flex-direction: column;
  gap: 10%;
  border: solid #999999ff 2px;
  padding: 3%;
  overflow-y: scroll;
  height: 50%;
  width: 100%;
}

.not-correct-content {
  color: red;
}

.countdown-span {
  color: #ff0000;
  height: 40vh;
  width: 50%;

  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-login {
  height: 60%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.info-login-picture {
  height: 70%;
  object-fit: contain;
  aspect-ratio: 1 / 1;
}

.info-login-hand {
  position: absolute;
  height: 40%;
  aspect-ratio: 1 / 1.2;
  z-index: 10;
}

.info-login-collapse {
  display: none;
  height: calc(100vh - 3 * 70vw / 6 - 2 * calc(70vw * 70 / 500) - 2 * 15vh);
}

.info-login-collapse {
  border: 1px solid gainsboro;
  background-color: white;
  padding: 10px;
  position: relative;
}

.info-login-collapse-landscape {
  border: 1px solid gainsboro;
  background-color: white;
  padding: 10px;
  position: relative;
  display: none;
  height: 50vh;
}

.info-login-collapse-landscape p {
  font-size: 15px;
  padding: 5%;
  padding-top: 10%;
  overflow-y: auto;
  height: 100%;
}

.info-login-collapse-landscape p > img {
  height: 3vh;
}

.info-login-collapse p > img {
  height: 3vh;
}

.info-login-collapse p {
  font-size: 15px;
  padding: 5%;
  padding-top: 10%;
  overflow-y: auto;
  height: 100%;
}

.cancel-message-login {
  position: absolute;
  top: 2%;
  right: 2%;
  height: 10%;
  aspect-ratio: 1/1;
}
.cancel-message-login > img {
  width: 100%;
  height: 100%;
}

.wrapper-login-input-value {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 10%;
  align-items: center;

  justify-content: flex-end;
}

.wrapper-login-input-value > img {
  width: 10%;
  object-fit: contain;
}

.wrapper-login-text-picture {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: calc(100vh - 3 * 70vw / 6 - 2 * calc(70vw * 60 / 600) - 2 * 12vh);
  position: relative;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-height: 1000px) {
  .info-login-collapse p {
    font-size: 2.6cqmin;
  }
}

@media only screen and (max-width: 730px) {
  .login-wrapper-landscape {
    display: none;
  }
}

@media (orientation: landscape) {
  .flags-down-button {
    top: -330%;
  }

  .landscape-info {
    height: 100%;
    padding: 2%;
  }
}

@media only screen and (min-width: 460px) {
  .login-wrapper-landscape {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1%;
  }

  .landscape-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  .login-form-landscape {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    gap: 5%;
    max-height: 700px;
  }

  .login-form-landscape > input {
    font-size: 22px;
    width: 100%;
    aspect-ratio: 6.45/1;
    height: unset;
  }

  .input-key > input {
    /* height: 60px; */
    font-size: 22px;
    aspect-ratio: 1 / 1;
    height: 100%;
  }

  .login-button > button {
    height: 100%;
    width: 90%;
    margin: auto;
  }

  .landscape-pair {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .scrollable-content {
    height: 100%; /* Set the height according to your design */
    overflow-y: scroll;
  }

  .register-wrapper {
    display: flex;
    flex-direction: column;

    align-items: normal;
  }

  .register-wrapper-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .register-wrapper-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .register-button > button,
  .login-button > button {
    margin: 0px;
    width: 100%;
  }

  .register-button {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .login-button {
    height: 15%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 10%;
  }

  .reg {
    margin: 0;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
