.form-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 15px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.form-top-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
}

.form-top-buttons img {
    width: 45%;
    height: auto;
}

.top-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.top-buttons img {
    width: 45%;
    height: auto;
}