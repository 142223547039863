.page-wrapper {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 5;
    background-color: white;
}

.landing-page {
    width: 95vw;
    margin: 0 auto;
    row-gap: 2rem;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing-page--book {
    position: relative;
    margin-top: 1rem;
    font-size: 1.2rem;
    width: 100%;
}

.landing-page--book--main-img {
    width: 80%;
}

.landing-page--book--stats {
    position: absolute;
    width: 80%;
    display: flex;
    margin-bottom: 15%;
}

.landing-page--book--stats--list {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
    list-style: none;
    font-size: 1.2rem;
    font-weight: 500;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.landing-page--book--stats--list--item {
    display: flex;
    width: 5rem;
    align-items: center;
}


.landing-page--book--stats--list > li > img {
   height: 1.5rem;
   max-width: 2rem;
}

.landing-page--description {
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-left: 1rem;

}

.landing-page--description--color-list {
    list-style: none;
    margin: 8px 0 4px 8px;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.color-guide {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.colored-box {
    background-size: cover;
}

.landing-page--buttons {
    font-size: 6rem;
}

.landing-page--buttons > div > img {
    height: 3rem;
}

.landing-page--skip-button > img {
    width: 7rem;
}

.landing-page--button {
    border: 3px solid black;
    border-radius: 50%;
    height: 7rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media only screen and (min-width: 600px) {
    .landing-page {
        display: flex;
        flex-direction: row;
        position: relative;
        font-size: 2rem;
    }

    .landing-page--buttons {
        flex-direction: column;
    }


    .landing-page--book--main-img {
        display: none;
    }

    .landing-page--book--stats {
        position: relative;
        margin-bottom: 1rem;
        flex-direction: column;
        gap: 2rem;
    }
}