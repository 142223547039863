.left-side-message {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 3%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f2f2f2;
  gap: 5px;
  color: #fff;
  user-select: none;
  z-index: 20;
}
