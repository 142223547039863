.languages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.edit-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.language-text-app {
  width: 80%;
  height: 100%;
}
.language-text-app > input {
  width: 100%;
}

.language-wrapper-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  align-items: center;
  padding-bottom: 5%;

  position: fixed;
  max-height: 100%;
  max-width: 100%;
}
