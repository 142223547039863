* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pdf-loader {
  width: 100%;
  padding: 5%;
  overflow-y: auto;
  font-size: 10px;
  background-color: #fff;
}

/* PDF HEADER */

.pdf-header {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upper-header {
  width: 100%;
  height: 60%;
  display: flex;
  border: 1px solid gray;
}

.upper-header-img {
  height: 100%;
  width: 16.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upper-header-img > img {
  height: 70%;
  width: 90%;
}

.upper-header-data {
  flex: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.upper-header-data-top {
  width: 100%;
  flex: 2;
  display: flex;
}

.upper-header-cell {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
}

.upper-header-data-middle {
  flex: 1;
  width: 100%;
  display: flex;
}

.upper-header-data-bottom {
  width: 100%;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid gray;
}

.bottom-header {
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  border-right: 1px solid gray;
}

.bottom-header-row {
  display: flex;
  flex: 1;
  width: 100%;
}

.bottom-header-cell {
  width: 17%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  text-align: center;
}

.bottom-header-cell > span {
  width: 90%;
  padding: 0 8%;
  overflow-wrap: break-word;
}

.bottom-header-cell-custom {
  width: 17%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
}

.bottom-header-cell-custom > span {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* PDF ITEM */

.pdf-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10vh;
  gap: 5vh;
}

.pdf-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
}

.pdf-item-header {
  height: 12vh;
  width: 100%;
  display: flex;
}

.pdf-item-header-left {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
}

.pdf-item-header-left.empty {
  border-right: none;
  border-left: none;
  display: flex;
  flex-direction: column;
}

.pdf-item-category {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-item-categories {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.pdf-item-category-header {
  width: 20%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-item-header-middle {
  flex: 9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid gray;
  padding-left: 2%;
}

.pdf-item-header-right {
  flex: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pdf-item-header-data {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid gray;
  border-left: none;
  border-bottom: none;
}

.pdf-item-header-data:last-child {
  border-bottom: 1px solid gray;
}

.pdf-item-data {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.pdf-item-data-title {
  font-size: 12px;
}

.pdf-item-data-content {
  font-size: 10px;
}

.pdf-item-images {
  width: 100%;
  display: flex;
  height: 15vh;
  gap: 2vw;
}

.pdf-item-images > img {
  height: 100%;
  flex: 1;
  max-width: 20%;
  object-fit: contain;
}

.pdf-item-document {
  height: 3vh;
  display: flex;
  gap: 1vw;
  align-items: center;
}

.pdf-item-document > img {
  height: 100%;
  aspect-ratio: 1/1;
}

.pdf-item-document > span {
  font-size: 10px;
}

.pdf-item-comment {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
  padding-left: 5%;
}

.pdf-item-comment-header {
  display: flex;
  width: 100%;
  gap: 5%;
}

.pdf-item-comment > img {
  height: 15vh;
  width: 40%;
  object-fit: contain;
}
